.agency-profile-container {
  color: var(--themeColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  line-height: 0;
  border: 1px solid #8ca5a5;
  width: 420px;
  height: 485px;
  margin: 20px 0px 20px 0px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  background: #fff;
  padding: 0px 18px;
  padding-top: 25px;
  margin: 20px auto;

  .promotion-image {
    width: 300px;
    height: 220px;
    border-radius: 8px;
    object-fit: cover;
  }

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 4px;
}

.account-profile {
  width: 157px;
  height: 148px;
  border-radius: 50%;
  border: 2.5px solid #ffffff;
  position: relative;
  display: inline-block;
  object-fit: cover;
  background: linear-gradient(
    135deg,
    #fff 0%,
    #fff 25%,
    #225eb3 50%,
    #ff30d2 75%,
    #600585 100%
  );
  background-size: 400% 400%;
  background-repeat: no-repeat;

  &:hover {
    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
        border-box !important;
  }

  @media screen and (min-width: 700px) and (max-width: 1200px) {
    width: 144px;
    height: 147px;
  }
}

.fname {
  // color: #01a8ac;
  font-weight: 600;
  width: 52%;
  margin-top: 0.5rem;
}

.profile-text {
  display: flex;
  gap: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  text-align: start;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .agency-profile-container {
    width: 90%;
    // height: 100%;
    padding: 15px;
    margin: 10px auto;
  }
}
