.btn {
  width: 79px;
  height: 38px;
  background: #fff;
  color: #3a9545;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
      border-box !important;
  border-radius: 33px;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    border: 3px solid transparent;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
        border-box !important;
  }
}
