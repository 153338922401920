.AdDetailsContainer {
    h1 {
        text-align: center;
    }
}

.DivedeInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0px;
}

.multiSelectLabel {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: .5rem;
    font-size: 14px;
    color: var(--themeColor);
}

.multiselect {
    width: 100%;
    padding: 3px;
    border: 1px solid rgb(244, 239, 239);
    border-radius: 33px;
}