 .card-main {
    padding: 22px;
    color: var(--themeColor);
    .AddYoursButton{
      // float: right;
      display: flex;
      justify-content: right;
      padding-bottom: 10px;
    }
 }

 .pagination {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0px 20px 0px;
 }

 .no-ads-container{
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   font-size: 18px;
   gap: 4px;
   height: 80vh;
 }