.ContactUs_main {
  height: 133px;
  width: 868px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: linear-gradient(#0faa2b, #057d1c);
  margin: 0 auto;
  h1 {
    color: #ffffff;
    font-size: 28px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
  }

  .button_container {
    .contactUs_Button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      height: 55px;
      width: 213px;
      background-color: #ffff;
      border-radius: 10px;

      img {
        height: 16px;
        width: 26px;
      }
    }
  }
}
