.Error-container {
    box-shadow: 0px 6px 12px #0000001a;
    border-radius: 16px;
    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    width: 580px;
    height: 300px;
    background: #fff;
    padding: 14px;
    color: var(--themeColor);

    .Error-main-container {
        text-align: center;
        margin-top: 20%;

        .error-text {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .gotoProfile {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;

            &:hover {
                font-weight: bold;
            }
        }
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: auto;
        height: auto;
    }
}

.image_close {
    width: 19.5px;
    height: 19.5px;
    cursor: pointer;
}

.Error-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    color: #01a8ac;
    padding: 15px;
    border-bottom: 1px solid #f4f6f6;
    width: 100%;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        padding: 21px;
    }

    .close-button {
        cursor: pointer;
    }
}