.testimonials {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  .testimonials_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    margin-bottom: 100px;
    gap: 10px;
    .testimonials_heading {
      color: #313131;
      font-size: 85px;
      font-weight: 700;
    }
    .testimonials_description {
      text-align: center;
      line-height: 1.9;
    }
  }
  .testimonialList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    .testimonialList_box {
      height: 273px;
      width: 573px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: #e7f5e9;

      .testimonialContent {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // gap: 30%;
        padding: 30px;

        .testimonialContent_description {
          font-family: "Roboto", sans-serif;
          font-style: italic;
          color: #5f7285;
          font-size: 14px;
        }

        .testimonialContent_profile {
          display: flex;
          gap: 20px;
          height: 48px;
          width: auto;
          .profile_Img {
            height: 50px;
            width: 50px;
          }
          .profile_details {
            display: flex;
            flex-direction: column;
            font-family: "Roboto", sans-serif;

            h2 {
              color: #1b263d;
              font-size: 16px;
              font-weight: 400;
              font-family: "Roboto", sans-serif;
            }
            p {
              white-space: nowrap;
              color: #9c9ea3;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  //   background-color: yellow;
  .finishIcon {
    img {
      width: 114px;
      height: 18px;
      margin: 100px 0px 100px 0px;
      //   background-color: red;
    }
  }
}

@media (max-width: 475px) {
  .testimonials {
    width: 90%;
    .testimonials_heading {
      font-size: 45px !important;
    }
  }
}

@media (max-width: 1820px) {
  .testimonialList {
    // background-color: red;
    .testimonialList_box {
      height: 190px !important;
      width: 413px !important;
    }
  }
}

@media (max-width: 1320px) {
  .testimonialList {
    // background-color: red;
    .testimonialList_box {
      height: 290px !important;
      width: 813px !important;

      .testimonialContent_description {
        font-size: 18px !important;
      }
    }
  }
}

@media (max-width: 830px) {
  .testimonialList {
    // background-color: red;
    .testimonialList_box {
      height: 49vh !important;
      width: 89vw !important;

      .testimonialContent_description {
        font-size: 18px !important;
      }
    }
  }
}
