.footer {
  color: var(--textColor);
  .footer_main {
    position: relative;
    background-color: #02300a;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    align-items: center;
    padding: 50px;
    color: #fff;
    flex-wrap: wrap;
    margin-top: 100px;
    position: relative;
    z-index: 999;
    height: 500px;

    .AdSimpleLogo {
      position: absolute;
      height: 286px;
      width: 1029px;
    }
    .appLogo {
      flex: 1;
      max-width: 300px;
      h1 {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: 800;
      }

      p {
        margin-bottom: 20px;
        //   margin-top: 40px;
        font-size: 14px;
      }

      .socialmediaIcon {
        display: flex;

        gap: 10px;
        .socialmediaIcon_subContainer {
          height: 30px;
          width: 30px;
          border: 1px solid var(--textColor);

          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
        img {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .usefulLinks,
    .ourServices,
    .contactInfo {
      flex: 1;
      max-width: 250px;

      h1 {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 800;
      }

      .usefulLinks_section,
      .ourServices_section,
      .contactInfo_section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        a,
        p {
          font-size: 14px;
          text-decoration: none;
          color: #fff;

          &:hover {
            color: #8cffa4;
          }
        }
      }
    }

    .contactInfo_section {
      .contactInfo_subSection {
        display: flex;
        gap: 10px;
        align-items: center;
        //   margin-bottom: 10px;

        img {
          height: 20px;
          width: 20px;
        }

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }

    .rocketUpIcon {
      width: 66px;
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      align-self: flex-end;
      cursor: pointer;
      background-image: linear-gradient(#0faa2b, #057d1c);
      img {
        width: 27px;
      }
    }
  }

  .footer-bottom {
    // position: absolute;
    background-image: linear-gradient(#0faa2b, #057d1c);
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
  }
}
