.login-form {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #e2e9e9;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.login-page {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}

.login-form img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
  opacity: 0.7 black;
  transition: opacity 0.3s ease;
}

.login-cover {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-cover img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.login-page-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.login-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 33px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 550px;
  height: 650px;
  margin-top: 50px;

  @media screen and (max-width: 661px) {
    padding: 11px;
    width: 93.667vw;
  }
}

.login-page-wrapper-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 33px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 70px 0;
  width: 450px;

  @media screen and (max-width: 661px) {
    width: auto;
  }
}

.admin-panel {
  background: var(--themeColor);
  width: 148px;
  border-radius: 38px;
  text-align: center;
  padding: 6px 13px;
}

.admin-panel span {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 700;
  color: #ffffff;
}

.visueats-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.login-container {
  margin-top: 20px;
  margin-bottom: 25px;
}

.login-container div {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--themeColor);
  margin-bottom: 5px;
}

.login-container p {
  font-family: "Century Gothic";
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: var(--themeColor);
  margin-top: 16px;
}

.email-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 8px;
  position: relative;
  max-height: 55%;
  overflow: auto;
}

.input-email-container {
  display: flex;
  align-items: center;
}

.otp-input {
  text-align: center;
  gap: 25px;
}

.register-now {
  text-align: center;
}

.register-now p {
  color: black;
}

.register-now p span {
  color: var(--themeColor);
  font-weight: 500;
  cursor: pointer;
}
