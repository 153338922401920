.main {
    width: 100vw;

    .templatePreview {
        display: flex;
        justify-content: center;  
        padding: 20px;

      }
      
      .templatePreview_card {
        display: flex;
        flex-direction: row;  
        width: 100vw;  
        max-width: 900px;  
        // background-color: grey; 
        border-radius: 10px; 
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
        overflow: hidden;  
        cursor: pointer; 
        // transition: transform 0.3s; 
        // gap: 20px; 
        
        // &:hover {
        //   transform: translateY(-5px); // Slight lift on hover
        // }
      }
      
      .templatePreview_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;  
        // padding: 15px;  
       }
      
      img {
        // width: 100vw; // Image takes full width of its container
        height: auto;  
        border-radius: 8px; 
        background-color: #8aa88b;
      }
      
    .main_header {
        display: flex;
        justify-content: space-between;
        padding: 50px 90px 0px 90px;

        .heading h1 {
            font-weight: 500;
            font-size: large;
        }

        .heading-buttons_main {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .heading-buttons{
                display: flex;
                gap: 10px;
            }
        }

    }

    .canva_Main {
        border: 1px solid #897a7a;
        width: 90%;
        height: 100%;

        h1 {
            color: grey;
            text-align: center;
            font-size: 30px;
            font-weight: 800;
        }

        .grid-container {
            display: grid;
            grid-template-areas: 
                "menu main main"
                "menu footer footer";
            grid-gap: 10px;
        }

        .grid-leftSide {
            grid-area: menu;
            padding-left: 10px;

            .grid-leftSide-title {
                cursor: pointer;
                padding: 10px;
                background-color: lightgray;
                border: 1px solid gray;
                margin-bottom: 10px;
                border-radius: 4px;
                text-align: center;
                &:hover {
                    background-color: gray;
                    color: white;
                }
            }

            .image-upload-label {
                display: block;
                padding: 10px;
                text-align: center;
                background: linear-gradient(to bottom right, rgb(58, 168, 199), rgb(35, 182, 79));
                cursor: pointer;
                border-radius: 5px;
                margin-bottom: 10px;
            }
        }

        .item5 {
            grid-area: footer;
            position: relative;

            .deleteButtonDisable,
            .deleteButtonEnable {
                float: right;
                font-size: small;
                margin-right: 10px;
                padding: 5px 20px;
                border-radius: 4px;
                cursor: pointer;
                border: 1px dotted grey;
            }

            .deleteButtonDisable {
                background-color: lightgrey;
            }

            .deleteButtonEnable {
                background-color: rgba(212, 12, 12, 0.724);
                color: white;
            }

            .saveButton {
                float: right;
                font-size: small;
                margin: 10px 20px;
                padding: 5px 25px;
                border-radius: 4px;

                cursor: pointer;
                background-color: green;
                color: white;
                border: 1px solid darkgreen;
                
            }

           
        }
    }
}

@media screen and (min-width: 700px) {
        .main {
            .main_header{
                padding: 50px 42px 0px 90px;
            }
            .templatePreview_content{
                text-align: center;
            }
        }
    }

 