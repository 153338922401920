.settings {
    position: absolute; // Changed from fixed to absolute for dynamic positioning
    gap: 8px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    background-color: #d2cbcb;
    padding: 16px;
    border-radius: 4px;
    text-align: left;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; // Ensure it appears above canvas objects

    .input_color {
        height: 40px;
    }
}
