.upload-image-button {
  color: #8ca5a5;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0;
  border: 1px dashed #8ca5a5;
  width: 195px;
  height: 111px;
  margin: 20px auto;
  border-radius: 8px;
  padding-top: 16px;
  cursor: pointer;
  outline: none;
  background: #fff;
  justify-content: center;

  .upload-image-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    color: #7b9191;
    margin-top: 4px;
    font-family: "Poppins";
  }

  .upload-icon {
    border: 1px dashed #7b9191;
    width: 157px;
    height: 148px;
    border-radius: 50%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.image_gallery_icon {
  width: 23px;
  height: 22px;
  margin-bottom: 8px;
}

.upload-image_subtext {
  font-size: 12px;
  line-height: 14px;
  font-family: "Poppins";
}