.nav-Container {
  // width: 100vw;

  .header {
    background-color: white;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

    height: 7vh;
    padding: 0px 30px 0px 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 100px;
      height: 40px;
      cursor: pointer;
    }

    .nav-btn {
      display: flex;
      align-items: center;
      gap: 20px;

      .nabar {
        ul {
          color: var(--themeColor);
          font-weight: 600;
          display: flex;
          text-decoration: none;
          list-style: none;
          gap: 27px;

          li {
            cursor: pointer;

          }

          .active {
            border-bottom: 1.5px solid #49557e;
          }


        }
      }

      .buttons {
        display: flex;
        gap: 20px;
      }
    }
  }
}

.navbar{
  display: flex;
  gap: 25px;
  color: var(--themeColor);
  span {
    .dropdownlogo{
      width: 40px;
      height: 40px;
     
    }
  }
  
}

.selectednav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 800;
  direction: ltr;
  cursor: pointer;
  z-index: 1;
  transition: color .2s;
  border-radius: 8px;
}