.template-main {
    width: 100%;
    height: 100vh;
    // background-color: red;

    
    .template-canva_Main {
        border: 1px dotted darkgreen;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        height: 70%;
 
        h1 {
            color: grey;
            text-align: center;
            font-size: 30px;
            font-weight: 800;
        }

        // .template-grid-container {
        //     display: grid;
        //     grid-template-areas:
        //         "menu main main"
        //         "menu footer footer";
        //     grid-gap: 10px;

            .deleteButtonDisable,
            .deleteButtonEnable {
                // float: right;
                font-size: small;
                margin-right: 10px;
                padding: 5px 20px;
                border-radius: 4px;
                float: left;
                cursor: pointer;
                border: 1px dotted grey;
            }

            // .deleteButtonDisable {
            //     background-color: lightgrey;
            //     height: 30px;
            //     width: 100px;
            // }

            .deleteButtonEnable {
                background-color: rgba(212, 12, 12, 0.724);
                color: white;
                &:hover {
                    background: linear-gradient(117.16deg,
                            #e94f3b 14.24%,
                            #d70b0b 52.69%,
                            #c91010 93.83%);
                }
            }
        // }

        .template-leftSide {
          width: 10%;
            .leftSide-title {

                background-color: var(--themeColor);
                padding: 8px 32px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                border-radius: 33px;
                color: #fff;
                font-weight: 600;
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 22px;
                cursor: pointer;
            
                &:hover {
                    background: linear-gradient(117.16deg,
                            #b2d236 14.24%,
                            #2bb24b 52.69%,
                            #000 93.83%);
                }
            
                @media screen and (min-width: 300px) and (max-width: 550px) {
                    font-size: 12px;
                }

                // cursor: pointer;
                // padding: 10px;
                // background-color: lightgray;
                // border: 1px solid gray;
                // margin-bottom: 10px;
                // border-radius: 4px;
                // text-align: center;

                // &:hover {
                //     background-color: gray;
                //     color: white;
                // }
            }

            .image-upload-label {
                display: block;
                padding: 10px;
                text-align: center;
                background: linear-gradient(to bottom right, rgb(58, 168, 199), rgb(35, 182, 79));
                cursor: pointer;
                border-radius: 5px;
                margin-bottom: 10px;
            }
        }

        .template-rightSide {
             width: 90%;
            //  background-color: blue;
             display: flex;
             flex-direction: column;
             gap: 10px;


        
             .responsive-canvas{
                width: 100%;
                height: 100%;

                .canvas-container{
                    width: 100% !important;
                    height: 100% !important;
                }
             }

            // .responsive-canvas {
            //     width: 100% !important;  /* Set the desired width in percentage */
            //     height: 100% !important; /* Set the desired height in percentage */
            //     // display: block; /* Removes extra space under the canvas */
            //     margin: 0 auto; /* Centers the canvas horizontally */
            //   }
            //   .canvas-container{
            //     width: 100% !important;  /* Set the desired width in percentage */
            //     height: 100% !important;
            //   }

        }
    }
    .footer-buttons {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        margin: 10px;
        gap: 30px;
    }

    .cancelButton {
        float: right;
        font-size: small;
        margin: 10px 20px;
        padding: 5px 25px;
        border-radius: 4px;

        cursor: pointer;
        background-color: rgb(241, 35, 35);
        color: white;
        border: 1px solid black;

    }

    .saveButton {
        float: right;
        font-size: small;
        margin: 10px 20px;
        padding: 5px 25px;
        border-radius: 4px;

        cursor: pointer;
        background-color: green;
        color: white;
        border: 1px solid black;

    }
}

.denied-button {
    background-color: #fff;
    padding: 8px 24px;
    color: var(--themeColor);
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    border-radius: 33px;
    border: 1px solid var(--primary-teal-500-main, --themeColor);
    background: var(--shades-0, #fff);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        border: 2.5px solid var(--themeColor);
    }
}

.save-button {
    background-color: var(--themeColor);
    padding: 8px 32px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    // cursor: pointer;

    &:hover {
        background: linear-gradient(117.16deg,
                #b2d236 14.24%,
                #2bb24b 52.69%,
                #000 93.83%);
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
        font-size: 12px;
    }
}