
.createTemplate-container {
    box-shadow: 0px 6px 12px #0000001a;
    border-radius: 16px;
    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    width: 90vw;
    height: 90vh;
    overflow: hidden;
    background: #fff;
    padding: 14px;
    

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: auto;
        height: auto;
    }
}

.image_close {
    width: 19.5px;
    height: 19.5px;
    cursor: pointer;
}

.ant-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
  
.ant-modal-footer {
    display: none;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-modal .ant-modal-content {
    background: none;
    box-shadow: none;
    border-radius: none;
    

    .ant-modal-close {
        display: none;
    }
}

.denied-changes {
    background-color: var(--themeColor);
    padding: 8px 32px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(117.16deg,
                #b2d236 14.24%,
                #2bb24b 52.69%,
                #000 93.83%);
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
        font-size: 12px;
    }
}