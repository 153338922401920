.profile-dropdown-container {
  background-color: #fff;
  box-shadow: 0px 6px 12px #0000001a;
  width: 20%;
  height: 35vh;
  position: absolute;
  right: 16px;
  top: 10px;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;
  color: var(--themeColor);
  overflow: auto;
  .backArrow {
    height: 30px;
    width: 30px;
    cursor: pointer;
    float: left;
    z-index: 999;
    // filter: invert(24%) sepia(193%) saturate(232%) hue-rotate(139deg)
    //   brightness(100%) contrast(90%); //change black bg of back arrow image to themecolor

    filter: invert(31%) sepia(67%) saturate(497%) hue-rotate(86deg)
      brightness(94%) contrast(93%);
  }
}
.loadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .rightArrow {
    height: 20px;
    width: 20px;
    color: var(--themeColor);
    // filter: invert(24%) sepia(193%) saturate(232%) hue-rotate(139deg)
    //   brightness(100%) contrast(90%); //change black color of arrow to themecolor

    filter: invert(31%) sepia(67%) saturate(497%) hue-rotate(86deg)
      brightness(94%) contrast(93%);
  }
}
.profile-dropdown {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  padding-bottom: 5px;
  cursor: pointer;
}
.profile-dropdownSelected {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  padding-bottom: 5px;
  cursor: pointer;
  background: rgba(229, 231, 235, 1);
  border-radius: 8px;
  padding: 5px;
}

.profile-dropdown:hover {
  background: rgba(229, 231, 235, 1);
  border-radius: 8px;
  padding: 5px;
}

.account_profile-agency {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2.5px solid #ffffff;
  position: relative;
  display: inline-block;
  object-fit: cover;
  background: #3a9545;

  .no-account-img {
    position: absolute;
    top: 58px;
    left: 0px;
    z-index: 1;

    svg {
      font-size: 18px;
      color: #fff;
    }
  }
}
.no-profiles-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  height: -webkit-fill-available;
}

@media screen and (max-width: 950px) {
  .profile-dropdown-container {
    width: 30%;
    // height: 30vh;
  }
}
