.card {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  .card_Ad {
    position: relative;
    cursor: pointer;
    border: 1px solid #e2e2e2;

    .cardDots {
      position: absolute;
      right: 1px;

      .myDropdownButton {
        text-align: right;

        button:first-of-type {
          display: none;
          background-color: red !important;
        }

        .ant-btn {
          background: transparent;
          border-radius: 10px;
          padding: 15px;
          margin: 12px;
          border: 2px solid gray !important;
        }

        svg {
          transform: rotate(90deg);
          font-weight: 700;
          color: black !important;
          font-size: 30px;
        }
      }
    }

    img {
      width: 100%;
      height: 400px;
    }

    .status {
      position: absolute;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 5px 10px;
      border-radius: 4px 4px 0px 0px;
      font-size: 19px;
      font-weight: 600;
      opacity: 0.8;
      text-align: center;
      width: 100%;

      .status_Pending {
        color: orange !important;
      }

      .status_Approve {
        color: green !important;
      }

      .status_Rejected {
        color: red !important;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
.card {
     display: flex;
     flex-direction: row;
  }
}