.DownloadApp_main {
  display: flex;
  margin: 0 auto;
  gap: 60px;
  width: 80%;
  //   justify-content: center;
  //   align-items: center;
  //   background-color: red;
  height: auto;
  margin: 100px auto;

  .DownloadApp_leftSide {
    display: flex;
    height: 0px !important;
    flex-direction: column;
    gap: 40px;
    width: 50%;
    .downloadApp_heading-container {
      .downloadApp_heading {
        color: #313131;
        font-size: 85px;
        font-weight: 700;
        line-height: 1;
      }
    }

    .downloadApp_desc-container {
      .downloadApp_desc {
        // text-align: center;
        line-height: 1.9;
        color: #575757;
      }
    }

    .downloadApp_store_container {
      display: flex !important;
      padding: 0 !important;
      height: 0 !important;
    }
    .stores {
      height: 40px !important; //to remove extra hight from this container and this .stores is used in DownloadStores.js
    }
  }

  .DownloadApp_rightSide {
    display: flex;
    gap: 50px;
    justify-content: space-around;
    width: 50%;
    position: relative;
    .image_Container1 {
      display: flex;

      .image_1 {
        height: 522px;
        width: 256px;
        z-index: 1;
        margin-top: 110px;
      }

      .image_Container2 {
        position: absolute;

        .image_2 {
          height: 629px;
          width: 309px;
          margin-left: 160px;
        }
      }
    }
  }
}
