.homepage-container {
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.homeContent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}

.homepage-flex-app {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}
.homepage-flex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  gap: 30px;
}

.ss-image {
  width: 58%;
  height: 60vh;
  border-radius: 10px;
}

.ss-image-cover {
  width: 30%;
  height: 60vh;
  margin-top: 2%;
  border-radius: 10px;
}

.homeContent {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
}

.homeContent span {
  color: var(--themeColor);
  font-weight: bold;
}
.download-button {
  background: var(--themeColor);
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
}
@media screen and (min-width: 750px) and (max-width: 1200px) {
  .homeContent {
    font-size: 16px;
    line-height: 1.5;
  }

  .ss-image {
    width: 80%;
  }
}

@media screen and (min-width: 300px) and (max-width: 749px) {
  .homepage-flex {
    grid-template-columns: 1fr;
  }
  .ss-image-cover {
    width: 58%;
  }
  .homepage-container {
    padding-top: 14%;
  }
}
