.countryDropdown {
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  color: var(--themeColor);
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  text-align: left;

  /* background-image: url("../../public/images/dropdownTheme.svg"); */
  background-repeat: no-repeat;
  background-position: calc(100% - 20px);
  background-size: 25px;
}

.p-multiselect-panel {
  background-color: white !important;

  &::placeholder {
    font-size: 14px;
  }

  .p-checkbox-box {
    border: 1px solid var(--themeColor);
  }
}

.p-checkbox .p-component .p-highlight {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}

.p-multiselect-item.p-highlight .p-checkbox .p-checkbox-box {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}

.p-multiselect-item {
  color: var(--themeColor);

  &:hover {
    font-weight: 600;
  }

  .p-highlight {
    background: #008f935c;
  }
}

.p-multiselect-token {
  background: white;
  // opacity: 0.5;
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor);
  padding: 3px 10px;
}

.p-inputwrapper-focus {
  border: 1px solid var(--themeColor) !important;
}

.p-multiselect-select-all .p-highlight .p-checkbox-box {
  background-color: #006265 !important;
}

.p-multiselect-panel {
  border-radius: 33px;
  // background: none;
}

.p-multiselect-header {
  border-radius: 33px 33px 0px 0px;
}
.label-edit-container {
  height: 180px;
  width: 180px;
}
.label-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
