.no-account-img {
    position: absolute;
    top: 58px;
    left: 69px;

    svg {
        font-size: 23px;
        color: #fff;
    }
}

.account_profile {
    position: relative;
    width: 157px;
    height: 148px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    object-fit: cover;
    background: #3a9545;
}

.profile-camera {
    position: absolute;
    left: 99px;
    top: 108px;
    background: #f9fbfb;
    border: 1px solid #e2e9e9;
    border-radius: 50%;
    width: 51px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        color: #3a9545;
        width: 23px;
        height: 21px;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        left: 88px;
    }
}

.agency-container {
    margin: 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 12px;
    align-items: center;
    width: 100%;
    height: 50vh;

    @media screen and (min-width: 300px) and (max-width: 750px) {
        padding: 60px 0px;
        display: -webkit-flex
    }
}