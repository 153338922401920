// /changing down arrow color

.css-13cymwt-control {
  border-radius: 20px;
}

.css-b62m3t-container {
  min-height: 36px !important;

  .css-9inhzv-control,
  .css-tre400-control,
  .css-50efka-control {
    .css-3w2yfm-ValueContainer {
      .css-1p3m7a8-multiValue {
        // background: none !important;
        // border: 1px solid #C0CECE;
        // border-radius: 12px !important;
        // margin-top: 2.5px;
        // margin-left: 5px;
      }
    }
  }
}

.css-9inhzv-control {
  .css-3w2yfm-ValueContainer {
    .css-15lsz6c-indicatorContainer {
      &:first-child {
        display: none !important;
      }
    }
  }
}

.css-1wy0on6 {
  display: none !important;
}

.css-1xc3v61-indicatorContainer {
  display: none !important;
}

.css-9inhzv-control {
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  // display: none;
  color: #01a8ac !important;
}

.css-1hb7zxy-IndicatorsContainer {
  .css-1xc3v61-indicatorContainer {
    display: none !important;
  }
}

.css-50efka-control {
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

//rotating down arrow
.css-15lsz6c-indicatorContainer {
  transform: rotate(180deg);
}

.css-1fdsijx-ValueContainer {
  color: var(--themeColor) !important;

  .css-1dimb5e-singleValue {
    color: var(--themeColor) !important;
    padding-left: 5px !important;

    &:disabled {
      color: #8ca5a5 !important;
    }
  }

  &:disabled {
    color: #8ca5a5 !important;
  }
}

.css-1dimb5e-singleValue {
  color: var(--themeColor) !important;
  padding-left: 5px !important;
}

//removing vertical bar
.css-1u9des2-indicatorSeparator {
  display: none;
}

// select area headear after clicking on it
.css-t3ipsp-control {
  border-radius: 18px 18px 0 0 !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
  box-shadow: none !important;
  border: 1px solid transparent !important;
  border-bottom: none !important;
  min-height: 36px;
  //   margin-top: 4px;
}

//Selection options window
.search_bar {
  .css-1jqq78o-placeholder {
    padding-left: 8px !important;
    font-size: 14px;
  }

  .css-qbdosj-Input {
    margin-left: 0px;
    color: var(--themeColor);
  }

  .css-166bipr-Input {
    margin-left: 8px;
    color: var(--themeColor);

    &:disabled {
      color: #8ca5a5 !important;
    }
  }

  .css-1dimb5e-singleValue {
    margin-left: 0px;
  }
}

.time-input {
  .css-1jqq78o-placeholder {
    padding-left: 25px !important;
    font-size: 14px;
  }

  .css-qbdosj-Input {
    margin-left: 23px;
    color: var(--themeColor);
  }

  .css-166bipr-Input {
    margin-left: 28px;
    color: var(--themeColor);
  }

  .css-1dimb5e-singleValue {
    margin-left: 25px;
    color: var(--themeColor) !important;

    &:disabled {
      color: #8ca5a5 !important;
    }
  }
}

.search_bar {
  .css-1nmdiq5-menu {
    margin-top: 0 !important;
    border-radius: 0 0 16.5px 16.5px !important;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
    border: 1px solid transparent !important;

    // border-top: none;
    //   margin: auto;
    .css-1n6sfyn-MenuList {
      width: 90%;
      margin: auto;

      //item option
      .css-d7l1ni-option,
      .css-10wo9uf-option {
        color: var(--themeColor) !important;
        font-size: 14px;
        padding: 5px 4px !important;
        //   margin: 5px 0;
        //   border-radius: 8px !important;
        overflow: hidden;

        &:hover {
          background-color: #e8f0f0;
        }
      }
    }

    .css-tr4s17-option {
      background-color: #01a8ac;
      color: #fff;
      margin-left: 0;

      &:focus {
        background-color: #fff;
        color: var(--themeColor);
      }
    }
  }
}

.time-input {
  .css-1nmdiq5-menu {
    margin-top: 0 !important;
    border-radius: 0 0 16.5px 16.5px !important;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
    border: 1px solid transparent !important;
    text-align: center !important;

    // border-top: none;
    //   margin: auto;
    .css-1n6sfyn-MenuList {
      width: 90%;
      margin: auto;

      //item option
      .css-d7l1ni-option,
      .css-10wo9uf-option {
        color: var(--themeColor) !important;
        font-size: 14px;
        padding: 5px 4px !important;
        //   margin: 5px 0;
        //   border-radius: 8px !important;
        overflow: hidden;

        &:hover {
          background-color: #e8f0f0;
        }
      }
    }

    .css-tr4s17-option {
      background-color: #01a8ac;
      color: #fff;
      margin-left: 0;

      &:focus {
        background-color: #fff;
        color: var(--themeColor);
      }
    }
  }
}

.css-1nmdiq5-menu {

  .css-d7l1ni-option,
  .css-10wo9uf-option {
    color: var(--themeColor) !important;
  }

  &::-webkit-scrollbar {
    width: 300px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    transform: rotate(90.07deg);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c0cece;
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {}
}

.css-1fdsijx-ValueContainer {
  .css-1jqq78o-placeholder {
    font-family: "Century Gothic" !important;
    font-style: normal;
    font-weight: 400;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #c0cece !important;
  }
}

.css-13cymwt-control,
input[type="text"],
input[type="number"],
input[type="tel"],
select {
  border-radius: 33px !important;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box;
    border: 1px solid transparent;

    &::placeholder {
      color: var(--themeColor);
    }

    .css-1jqq78o-placeholder {
      color: var(--themeColor);
    }
  }

  &:hover {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
    border: 1px solid transparent !important;
  }
}

.css-1xc3v61-indicatorContainer {
  //   .css-tj5bde-Svg {
  //     padding-right: 5px;
  //     display: none;
  //   }

  .css-1xc3v61-indicatorContainer svg {
    padding-right: 5px;
  }
}

.css-hwg5v7-control {
  align-items: center;

  // height: auto;
  .css-15lsz6c-indicatorContainer {
    .css-tj5bde-Svg {
      padding-left: 4px;
    }
  }
}

.css-8nhtki-control {
  height: auto;

  .css-15lsz6c-indicatorContainer {
    .css-tj5bde-Svg {
      padding-left: 4px;
    }
  }
}

.css-b62m3t-container {
  .css-15rgeqq-control {
    cursor: pointer;
    height: auto;
  }
}

.css-15rgeqq-control {
  height: auto;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.css-1bpx1xs-control {
  border-radius: 33px;
}