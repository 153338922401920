.save-container {
    box-shadow: 0px 6px 12px #0000001a;
    border-radius: 16px;
    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    width: 580px;
    height: 650px;
    background: #fff;
    padding: 14px;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: auto;
        height: auto;
    }
}

.save-container-ads {
    box-shadow: 0px 6px 12px #0000001a;
    border-radius: 16px;
    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    width: 980px;
    height: 85vh;
    background: #fff;
    padding: 14px;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: auto;
        height: auto;
    }
}

.image_close {
    width: 19.5px;
    height: 19.5px;
    cursor: pointer;
}

.save-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    color: #01a8ac;
    padding: 15px;
    border-bottom: 1px solid #f4f6f6;
    width: 100%;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        padding: 21px;
    }

    .close-button {
        cursor: pointer;
    }
}

.save-changes {
    background-color: #01a8ac;
    padding: 13px 45px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(117.16deg,
                #b2d236 14.24%,
                #2bb24b 52.69%,
                #01a8ac 93.83%);
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
        font-size: 12px;
    }
}

.payDetails {
    border: 2px solid #ccc;
    border-radius: 10px;
    margin: 20px;
    padding: 30px;
}

.continue-btn {
    background-color: #fff;
    padding: 13px 45px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    border: 1px solid #ef4444;
    color: #ef4444;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;

    &:hover {
        border: 2.5px solid #ef4444;
    }
}

.save-continue-btn {
    display: flex;
    // flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        flex-direction: row;
    }
}

.save-main-container {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 300px) and (max-width: 750px) {
        padding: 60px 0px;
        display: -webkit-flex
    }

    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--themeColor);
        margin-bottom: 30px;

        @media screen and (min-width: 300px) and (max-width: 550px) {
            font-size: 15px;
            margin-bottom: 26px;
            text-align: center;
        }
    }
}

.ant-modal-footer {
    display: none;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-modal .ant-modal-content {
    background: none;
    box-shadow: none;
    border-radius: none;

    .ant-modal-close {
        display: none;
    }
}

.denied-btns {
    justify-content: end;
    display: flex;
    gap: 25px;
}

.denied-btn {
    background-color: #fff;
    padding: 8px 18px;
    color: var(--themeColor);
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
    border-radius: 33px;
    border: 1px solid var(--primary-teal-500-main, var(--themeColor));
    background: var(--shades-0, #fff);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        border: 2.5px solid var(--themeColor);
    }
}

.denied-changes {
    background-color: var(--themeColor);
    padding: 8px 32px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 33px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(117.16deg,
                #b2d236 14.24%,
                #2bb24b 52.69%,
                #000 93.83%);
    }

    @media screen and (min-width: 300px) and (max-width: 550px) {
        font-size: 12px;
    }
}